<template>
  <div>
    <!-- <ayl-berad-nav :nav="nav"></ayl-berad-nav> -->
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="handleSelectionChange"></ayl-table>
        <div class="tac mt40px">
          <el-button type="success" class="btn-success-hw" @click="onTransferredIn">调入</el-button>
          <!-- <el-button
            type="danger"
            class="btn-danger"
            style="margin-left: 10px"
            @click="closePage()"
          >关闭</el-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransferredIn from "./transferred-in";
export default {
  mixins: [TransferredIn],
  data() {
    const vm = this;
    return {
      nav: [{ name: "城中村调入" }],

      identifier: ["villageId", 1], //唯一标识

      table: {
        api: vm.$api_hw.urbanVillagesManagement_listVillage,
        query: {
          queryContent: null,
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "城中村名称",
          },
        ],
        columns: [
          {
            type: "selection",
            width: "50px",
          },
          {
            title: "序号",
            width: "50px",
            $index: "index",
          },
          {
            title: "城中村名称",
            key: "villageName",
          },
          {
            title: "所属标段",
            key: "bidName",
          },
          {
            title: "人口数量",

            key: "population",
          },
          {
            title: "清扫保洁面积(m²)",

            key: "cleanArea",
          },
          {
            title: "范围",

            key: "villageScope",
          },
          {
            title: "地址",
            key: "villageAddress",
          },
        ],
      },
    };
  },
  methods: {
    closePage() {
      // console.log(1);
    },
  },
  async mounted() {
    // await this.$search(this.table);
  },
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
